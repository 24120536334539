/* eslint-disable camelcase */
import React from 'react';
import { history as historyPropTypes } from 'history-prop-types';
import { shape, string } from 'prop-types';
import { withRouter } from 'react-router-dom';
import Resume from '../../components/resume';
import Header from '../../components/header';
import ResumeApi from '../../api/resume';

class ResumeView extends React.Component {
  static propTypes = {
    history: shape(historyPropTypes).isRequired,
    match: shape({
      params: shape({
        name: string.isRequired,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      resume: {},
      resume_id: props.match.params.id,
      resume_name: '',
      loading: true,
    };
  }

  componentDidMount() {
    this.getResumeFromApi();
    window.scrollTo(0, 0);
  }

  getResumeFromApi() {
    const { resume_id } = this.state;
    const { history } = this.props;
    ResumeApi.getResume(resume_id)
      .then((ownedResume) => {
        this.setState({
          loading: false,
          resume_name: ownedResume.data.data.name,
          resume: ownedResume.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
        history.push('/login');
      });
  }

  render() {
    const { loading, resume_id, resume_name, resume } = this.state;
    return (
      <div>
        <Header printButton backButton />
        <Resume
          loading={loading}
          id={resume_id}
          name={resume_name}
          resume={resume}
          shared=""
        />
      </div>
    );
  }
}

export default withRouter(ResumeView);
