/* eslint-disable react/prop-types */
import React from 'react';

const T1Heading = ({ title }) => (
  <div className="jh-t1-heading">
    <h1>{title}</h1>
  </div>
);

export default T1Heading;
