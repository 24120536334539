import React from 'react';
import { Link } from 'react-router-dom';
import { history as historyPropTypes } from 'history-prop-types';
import { shape } from 'prop-types';
import moment from 'moment';
import ResumeApi from '../../api/resume';
import Header from '../../components/header';
import './resume.css';
import ResumeCreate from '../../components/forms/resume-create';
import LoadingAnimation from '../../components/loading-animation';

class ResumeList extends React.Component {
  static propTypes = {
    history: shape(historyPropTypes).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      resumes: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.getResumes();
    window.scrollTo(0, 0);
  }

  getResumes() {
    // eslint-disable-next-line react/prop-types
    const { history } = this.props;
    ResumeApi.getAllResumes()
      .then((allResumes) => {
        this.setState(
          {
            resumes: allResumes.data.data,
            loading: false,
          },
          () => {
            const { resumes } = this.state;
            if (resumes.length === 0) {
              history.push('resume-first');
            }
          }
        );
      })
      .catch((error) => {
        console.error(error);
        window.localStorage.clear();
        history.push('/login');
      });
  }

  render() {
    const { resumes, loading } = this.state;
    let allUserResumes;
    if (resumes[0]) {
      allUserResumes = resumes
        .sort((a, b) => {
          return b.resume_updated_at.localeCompare(a.resume_updated_at);
        })
        .map((resume, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Link key={index} to={`/resume/${resume.resume_id}`}>
              <div className="jh-resume-list-item">
                <h2>{resume.resume_name}</h2>
                <p>
                  Updated{' '}
                  {moment(resume.resume_updated_at).format('MMM Do YYYY')}
                </p>
              </div>
            </Link>
          );
        });
    }
    return (
      <div className="jh-resume-list-container">
        <Header printButton={false} />
        <div className="jh-resume-list">
          <h1 className="jh-resume-list-heading">MY RESUMES</h1>
          {allUserResumes}
          {loading && <LoadingAnimation />}
          <div className="jh-create-resume-form-container">
            <ResumeCreate />
          </div>
        </div>
      </div>
    );
  }
}

export default ResumeList;
