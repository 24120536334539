/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import './forms.css';
import ButtonText from '../buttons/button-text';

class ResumeEditKnowledge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      section: props.section,
      resume: props.resume,
      skills: props.resume.skills || [],
      firstRun: props.firstRun,
      loading: false,
      buttonDisabled: true,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ loading: props.loading });
  }

  handleInputChange(index, event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    const { skills, section } = this.state;
    skills[index] = {
      [name]: value,
      level: section,
    };

    this.setState(
      // eslint-disable-next-line no-unused-vars
      (prevState) => ({
        skills,
        buttonDisabled: false,
      }),
      () => {}
    );
  }

  handleSaveResumeChange(event) {
    event.preventDefault();
    const { skills } = this.state;
    const { resume } = this.state;
    resume.skills = skills;
    this.props.onResumeUpdated(resume);
  }

  render() {
    const listOfKnowledgeItems = this.state.skills.map((skill, index) => {
      if (skill.level === this.state.section) {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <label className="jh-form-label" key={index}>
            <h2>{this.state.section}</h2>
            <p className="jh-input-helper-text">
              Enter something you know well that is relevent to the role you are
              applying to
            </p>
            <div className="jh-input-container">
              <input
                required
                type="text"
                placeholder="Microsoft Excel"
                name="name"
                value={skill.name}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={this.handleInputChange.bind(this, index)}
              />
            </div>
          </label>
        );
      }
      return '';
    });

    return (
      <div className="jh-edit-form-container">
        <h1 className="jh-edit-form-heading">{this.state.section}</h1>
        <form
          className="jh-edit-form"
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={this.handleSaveResumeChange.bind(this)}
        >
          <p className="jh-edit-form-details">
            This is where we will list out the most relevent knowledge you have
            for the position you are applying for. List relevent things you
            know, not what you can do.
          </p>
          <div className="jh-form-group">{listOfKnowledgeItems}</div>
          <div className="jh-form-button-container">
            {this.state.firstRun === true && (
              <button disabled={this.state.buttonDisabled} type="submit">
                <ButtonText
                  loading={this.state.loading}
                  type="success"
                  text="SAVE AND CONTINUE"
                />
              </button>
            )}
            {this.state.firstRun === false && (
              <button disabled={this.state.buttonDisabled} type="submit">
                <ButtonText
                  loading={this.state.loading}
                  type="success"
                  text="SAVE KNOWLEDGE"
                />
              </button>
            )}
          </div>
        </form>
      </div>
    );
  }
}

export default ResumeEditKnowledge;
