/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import './t1.css';

class T1Block extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: props.list || [],
      title: props.title || '',
      type: props.type || '',
      company: props.company || '',
      startDate: props.startDate || '',
      endDate: props.endDate,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      list: props.list,
      title: props.title,
      type: props.type,
      company: props.company,
      startDate: props.startDate,
      endDate: props.endDate,
    });
  }

  render() {
    let list;
    if (this.state.list) {
      list = this.state.list.map((item, index) => {
        if (this.state.type === 'job') {
          return <li key={index}>{item}</li>;
        }
        if (this.state.type === 'education') {
          return (
            <li key={index}>
              {item.studyType} in {item.area} from the {item.institution}
            </li>
          );
        }
        if (this.state.type === 'interests') {
          return <li key={index}>{item.name}</li>;
        }
        if (item.level === this.state.title) {
          return <li key={index}>{item.name}</li>;
        }
        return '';
      });
    }
    let startDate;
    if (this.state.startDate) {
      startDate = moment(this.state.startDate).format('YYYY');
    }
    let endDate;
    if (this.state.endDate) {
      endDate = moment(this.state.endDate).format('YYYY');
    } else {
      endDate = 'Present';
    }
    return (
      <div className="jh-t1-block">
        <h1>{this.state.title}</h1>
        {this.state.company && (
          <div className="jh-t1-job-details">
            <h3>
              {this.state.company} &bull; {startDate} - {endDate}
            </h3>
            <h4>Accomplishments</h4>
          </div>
        )}
        <ul>{list}</ul>
      </div>
    );
  }
}

export default T1Block;
