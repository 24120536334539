/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { withRouter } from 'react-router-dom';
import './t1.css';
import T1Block from './block.js';
import T1Heading from './heading.js';
import LoadingAnimation from '../../../loading-animation';

class ResumeTemplate1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resume: props.resume || {},
      json_resume: {},
      id: props.id,
      shared: props.shared,
      loading: props.loading,
    };
  }

  componentDidMount() {}

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      resume: props.resume,
      json_resume: props.resume.json_resume,
      shared: props.shared,
      id: props.id,
      loading: props.loading,
    });
  }

  handleEditSection(section, event) {
    if (this.state.shared !== true) {
      this.props.history.push({
        pathname: `/resume/${this.state.id}/edit/${section}`,
        state: { resume: this.state.json_resume },
      });
    }
  }

  render() {
    let jobsList;
    if (this.state.json_resume && this.state.json_resume.work) {
      jobsList = this.state.json_resume.work.map((job, index) => {
        return (
          <T1Block
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            title={job.position}
            company={job.company}
            startDate={job.startDate}
            endDate={job.endDate}
            type="job"
            list={job.highlights}
          />
        );
      });
    }

    return (
      <div className="jh-resume-container jh-t1-container">
        <div className="jh-resume-page">
          <button
            type="button"
            className={`jh-t1-edit-section${this.state.shared}`}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={this.handleEditSection.bind(this, 'basics')}
            title="Click to edit basic info"
          >
            <section className="jh-t1-basics-section">
              {this.state.loading && <LoadingAnimation />}
              {this.state.json_resume && this.state.json_resume.basics && (
                <h1>{this.state.json_resume.basics.name}</h1>
              )}
              {this.state.json_resume && this.state.json_resume.basics && (
                <h2>{this.state.json_resume.basics.label}</h2>
              )}
              {this.state.json_resume && this.state.json_resume.basics && (
                <h3>
                  {this.state.json_resume.basics.email}{' '}
                  {this.state.json_resume.basics.phone}
                </h3>
              )}
            </section>
          </button>
          <T1Heading title="QUALIFICATIONS" />
          <section className="jh-t1-skills-section">
            <div className="jh-t1-skills-flex">
              <button
                type="button"
                className={`jh-t1-edit-section${this.state.shared}`}
                onClick={this.handleEditSection.bind(this, 'knowledge')}
              >
                <T1Block
                  title="knowledge"
                  type="skills"
                  list={this.state.json_resume.skills}
                />
                {this.state.loading && <LoadingAnimation />}
              </button>
              <button
                type="button"
                className={`jh-t1-edit-section${this.state.shared}`}
                onClick={this.handleEditSection.bind(this, 'experience')}
              >
                <T1Block
                  title="experience"
                  list={this.state.json_resume.skills}
                />
                {this.state.loading && <LoadingAnimation />}
              </button>
            </div>
            <div className="jh-t1-skills-flex">
              <button
                type="button"
                className={`jh-t1-edit-section${this.state.shared}`}
                onClick={this.handleEditSection.bind(this, 'skills')}
              >
                <T1Block title="skills" list={this.state.json_resume.skills} />
                {this.state.loading && <LoadingAnimation />}
              </button>
            </div>
            <div className="jh-t1-skills-flex">
              <button
                type="button"
                className={`jh-t1-edit-section${this.state.shared}`}
                onClick={this.handleEditSection.bind(this, 'interests')}
              >
                <T1Block
                  title="Items of Interest"
                  type="interests"
                  list={this.state.json_resume.interests}
                />
                {this.state.loading && <LoadingAnimation />}
              </button>
            </div>
          </section>
        </div>
        <div className="page-break" />
        <div className="jh-resume-page">
          <section className="jh-t1-basics-section">
            {this.state.json_resume && this.state.json_resume.basics && (
              <h4>
                {this.state.json_resume.basics.name} &bull;{' '}
                {this.state.json_resume.basics.email}
              </h4>
            )}
          </section>
          <T1Heading title="SELECT HISTORY" />
          <section className="jh-t1-skills-section">
            <button
              type="button"
              className={`jh-t1-edit-section${this.state.shared}`}
              onClick={this.handleEditSection.bind(this, 'jobs')}
            >
              {jobsList}
              {this.state.loading && <LoadingAnimation />}
            </button>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(ResumeTemplate1);
