/* eslint-disable react/prop-types */
import React from 'react';
import compromise from 'compromise';
import './nlp.css';

function capitalize(text) {
  return text.charAt(0).toLowerCase() + text.slice(1);
}
function getFirstWord(text) {
  return text.replace(/ .*/, '');
}
class NaturalGerund extends React.Component {
  constructor(props) {
    super(props);
    const nlpText = compromise(getFirstWord(props.text));
    const sentence = nlpText.sentences(0);
    this.state = {
      text: props.text,
      firstWord: capitalize(getFirstWord(props.text)),
      gerund: sentence.has('#Gerund'),
      nlp: sentence.verbs().conjugate()[0],
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    const nlpText = compromise(getFirstWord(props.text));
    const sentence = nlpText.sentences(0);
    this.setState({
      text: props.text,
      firstWord: capitalize(getFirstWord(props.text)),
      gerund: sentence.has('#Gerund'),
      nlp: sentence.verbs().conjugate()[0],
    });
  }

  render() {
    const { nlp, firstWord, gerund, text } = this.state;
    return (
      <div className="jh-nlp-container">
        {nlp && nlp.Gerund && nlp.Gerund !== firstWord && (
          <div>
            <h2>Recommendation:</h2>
            <p>
              Start with {JSON.stringify(nlp.Gerund)} instead of {firstWord}
            </p>
          </div>
        )}
        {gerund === false && text.length > 0 && (
          <div>
            <h2>Recommendation:</h2>
            <p>
              Start with a{' '}
              <a
                style={{ textDecoration: 'underline' }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://en.wikipedia.org/wiki/Gerund"
              >
                gerund verb
              </a>
            </p>
          </div>
        )}
      </div>
    );
  }
}

export default NaturalGerund;
