/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import './resume.css';
import ResumeTemplate1 from './templates/t1';
import ButtonText from '../buttons/button-text';

class Resume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resume: props.resume || {},
      shared: props.shared,
      name: props.name,
      id: props.id,
      loading: props.loading,
    };
  }

  componentDidMount() {}

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      resume: props.resume,
      shared: props.shared,
      name: props.name,
      id: props.id,
      loading: props.loading,
    });
  }

  render() {
    return (
      <div>
        {!this.state.shared && (
          <div className="jh-resume-menu jh-noprint">
            <div className="jh-resume-menu-flex">
              <div>
                <FacebookShareButton
                  url={`https://app.jobhero.org/resume-shared/${this.state.resume.share_id}`}
                  quote="Check out my resume! Created with jobhero.org"
                  hashtag="#jobheroresume"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </div>
              <div>
                <Link
                  target="_blank"
                  to={`/resume-shared/${this.state.resume.share_id}`}
                >
                  <ButtonText text="OPEN PUBLIC LINK" />
                </Link>
              </div>
            </div>
          </div>
        )}
        <ResumeTemplate1
          loading={this.state.loading}
          id={this.state.id}
          resume={this.state.resume}
          shared={this.state.shared}
        />
      </div>
    );
  }
}

export default Resume;
