/* eslint-disable no-undef */

import axios from 'axios';

let apiRoot;
if (window.location.hostname.includes('localhost')) {
  apiRoot = 'http://localhost:9000';
} else if (window.location.hostname.includes('jobhero')) {
  apiRoot = 'https://api.jobhero.org';
}

function getAuth() {
  let authToken;
  if (window.localStorage.user && JSON.parse(window.localStorage.user).token) {
    authToken = JSON.parse(window.localStorage.user).token;
  }
  return authToken;
}

function getAllResumes() {
  return axios({
    method: 'get',
    url: `${apiRoot}/api/v1/resume/`,
    headers: { authtoken: getAuth() },
  });
}

function getSharedResume(sharedId) {
  return axios({
    method: 'get',
    url: `${apiRoot}/api/v1/resume/shared/${sharedId}`,
  });
}

function updateSingleResume(resume) {
  return axios({
    method: 'put',
    url: `${apiRoot}/api/v1/resume/${resume.id}`,
    data: resume,
    headers: { authtoken: getAuth() },
  });
}

function getResume(id) {
  return axios({
    method: 'get',
    url: `${apiRoot}/api/v1/resume/${id}`,
    headers: { authtoken: getAuth() },
  });
}

function createResume(resume) {
  return axios({
    method: 'post',
    url: `${apiRoot}/api/v1/resume/create`,
    data: resume,
    headers: { authtoken: getAuth() },
  });
}

const ResumeApi = {
  getSharedResume,
  getResume,
  updateSingleResume,
  getAllResumes,
  createResume,
};
export default ResumeApi;
