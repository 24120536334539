import React from 'react';
import { Redirect } from 'react-router-dom';
import { shape, string } from 'prop-types';
import LoginUser from '../../components/forms/login-user';
import Header from '../../components/header';

class Login extends React.Component {
  static propTypes = {
    location: shape({
      pathname: string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      redirectToReferrer: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    // eslint-disable-next-line react/prop-types
    const { location } = this.props;
    const { from } = location.state || {
      from: { pathname: '/resume' },
    };
    const { redirectToReferrer } = this.state;

    if (redirectToReferrer) {
      return <Redirect to={from} />;
    }

    return (
      <div>
        <Header hideButtons />
        <LoginUser from={from} redirectToReferrer={redirectToReferrer} />
      </div>
    );
  }
}

export default Login;
