/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import './App.css';
import Main from './Main';

class App extends React.Component {
  render() {
    return (
      <div>
        <Main />
      </div>
    );
  }
}

export default App;
