/* eslint-disable react/prop-types */
import React from 'react';
import './buttons.css';
import LoadingAnimation from '../loading-animation';

const ButtonText = ({ type, loading, text }) => {
  let colors;
  if (type === 'success') {
    colors = {
      backgroundColor: '#7DDF64',
      border: '2px solid #7DDF64',
      color: '#333',
    };
  } else if (type === 'danger') {
    colors = {
      backgroundColor: '#AC3931',
      border: '2px solid #AC3931',
      color: '#eee',
    };
  } else {
    colors = {
      backgroundColor: 'none',
      border: '2px solid #aaa',
      color: '#aaa',
    };
  }

  return (
    <div className="jh-button-container" style={colors}>
      {loading === true && <LoadingAnimation />}
      {!loading === true && <h1>{text}</h1>}
    </div>
  );
};

export default ButtonText;
