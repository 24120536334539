import React from 'react';
import { withRouter } from 'react-router-dom';
import { history as historyPropTypes } from 'history-prop-types';
import { shape, string } from 'prop-types';
import Resume from '../../components/resume';
import ResumeApi from '../../api/resume';
import Header from '../../components/header';

class ResumeShared extends React.Component {
  static propTypes = {
    history: shape(historyPropTypes).isRequired,
    match: shape({
      params: shape({
        name: string.isRequired,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      resume: {},
      // eslint-disable-next-line react/prop-types
      resume_share_id: props.match.params.share_id,
      loading: true,
    };
  }

  componentDidMount() {
    this.getSharedResumeFromApi();
    window.scrollTo(0, 0);
  }

  getSharedResumeFromApi() {
    // eslint-disable-next-line camelcase
    const { resume_share_id } = this.state;
    // eslint-disable-next-line react/prop-types
    const { history } = this.props;
    ResumeApi.getSharedResume(resume_share_id)
      .then((sharedResume) => {
        this.setState({
          loading: false,
          resume: sharedResume.data.data,
        });
      })
      .catch((error) => {
        console.error(error);
        history.push('/login');
      });
  }

  render() {
    const { loading, resume } = this.state;
    return (
      <div>
        <Header printButton />
        <Resume loading={loading} resume={resume} shared />
      </div>
    );
  }
}

export default withRouter(ResumeShared);
