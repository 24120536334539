/* eslint-disable react/prop-types */
import React from 'react';
import compromise from 'compromise';
import compromiseSentences from 'compromise-sentences';
import './nlp.css';

compromise.extend(compromiseSentences);

function capitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}
class NaturalPast extends React.Component {
  constructor(props) {
    super(props);
    const nlpText = compromise(props.text);
    const sentence = nlpText.sentences();
    this.state = {
      text: props.text,
      nlp: capitalize(sentence.toPastTense().text()),
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(props) {
    const nlpText = compromise(props.text);
    const sentence = nlpText.sentences();
    this.setState({
      text: props.text,
      nlp: capitalize(sentence.toPastTense().text()),
    });
  }

  render() {
    const { text, nlp } = this.state;
    return (
      <div className="jh-nlp-container">
        {text !== nlp && (
          <div>
            <h2>Recommendation:</h2>
            <p>&quot;{nlp}&quot;</p>
          </div>
        )}
      </div>
    );
  }
}

export default NaturalPast;
