/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import './forms.css';
import Authentication from '../../api/authentication.js';
import ButtonText from '../buttons/button-text';

class RegisterUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {}

  registerUser(email, password) {
    this.setState({ loading: true });
    Authentication.registerUser(email, password)
      .then((user) => {
        window.localStorage.user = JSON.stringify(user.data.data);
        this.props.history.push('/resume');
      })
      .catch((error) => {
        console.error(error);
        toast.error(error.response.data.statusMessage, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        this.setState({
          loading: false,
          errorResponse: error.response,
        });
      });
  }

  handleSignIn(e) {
    e.preventDefault();
    const email = this.refs.email.value;
    const password = this.refs.password.value;
    this.registerUser(email, password);
  }

  render() {
    return (
      <div className="jh-enter-form-container">
        <form className="jh-enter-form" onSubmit={this.handleSignIn.bind(this)}>
          <h1 className="jh-enter-form-heading">REGISTER</h1>
          <p className="jh-edit-form-details">
            Create a new Job Hero account. This allows you to save unlimited
            resumes and improve on them over time.
          </p>
          <div className="jh-form-group">
            <label>
              <h1>EMAIL</h1>
              <div className="jh-input-container">
                <input
                  required
                  type="email"
                  ref="email"
                  placeholder="Enter email address"
                />
              </div>
            </label>
            <label>
              <h1>PASSWORD</h1>
              <div className="jh-input-container">
                <input
                  required
                  type="password"
                  ref="password"
                  placeholder="Enter password"
                />
              </div>
            </label>
          </div>

          <div className="jh-form-button-container">
            <div>
              <NavLink to="/login" activeClassName="">
                <ButtonText type="default" text="LOGIN" />
              </NavLink>
            </div>
            <div>
              <button type="submit">
                <ButtonText
                  loading={this.state.loading}
                  type="success"
                  text="REGISTER"
                />
              </button>
            </div>
          </div>
        </form>
        <ToastContainer />
      </div>
    );
  }
}

export default withRouter(RegisterUser);
